
  export default {
    name: `AeqSelectField`,
    props: {
      errorMessages: {
        type: [String, Array],
        default: () => []
      },
      items: {
        type: Array,
        default: () => []
      },
      itemText: {
        type: String,
        default: `text`
      },
      itemValue: {
        type: String,
        default: `value`
      },
      label: {
        type: String,
        default: ``
      },
      value: {
        type: [String, Number, Array, Object],
        default: ``
      },
      loading: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      returnObject: {
        type: Boolean,
        default: false
      },
      initial: {
        type: [String, Number, Array, Object],
        default: ``
      }
    },
    data() {
      return {
        filteredProps: {},
        innerValue: !!this.value ? this.value : this.initial
      }
    },
    computed: {
      traductedErrors() {
        return this.errorMessages.map((e) => this.$t(e))
      },
      innerItems: {
        get() {
          return this.items
        }
      }
    },
    watch: {
      items(newValue) {
        this.innerItems = newValue
      }
    },
    mounted() {
      const parentObject = { $props: {} }
      this.filteredProps = Object.assign(parentObject.$props, this.$attrs)
      this.filteredProps.label = this.label
      if (this.innerValue) {
        this.$emit(`input`, this.innerValue)
      }
    },
    methods: {
      setValue(val) {
        this.innerValue = this.$helpers.wrapInArray(val)
      }
    }
  }
