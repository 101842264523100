
export default {
  name: `AeqSchedule`,
  props: {
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    value: {
      type: [Array, String],
      required: true,
      default: () => [`` , ``]
    }
  },
  computed: {
    filteredListeners() {
      // Exclude the parent's input listener so that our handleInput is the only input handler
      const { input, ...others } = this.$listeners;
      return others;
    },
    displayValue() {
      const normalized = Array.isArray(this.value) ? this.value : [`` , ``];
      return normalized[0] === `number` ? (normalized[1] || ``) : 5;
    }
  },
  methods: {
    handleInput(newValue) {
      this.$emit(`input`, [`number`, newValue]);
    }
  }
};
